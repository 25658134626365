/* eslint-disable no-restricted-globals */
import React, { useState, useEffect } from "react";
import ThemeSetting from "./Include/ThemeSetting";
import "./styles/ITOutsourcing.css";
import { MdOutlineIntegrationInstructions } from "react-icons/md";
import { GrTechnology } from "react-icons/gr";
import { GiRelationshipBounds } from "react-icons/gi";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ImQuotesLeft, ImQuotesRight } from "react-icons/im";
import { Link, withRouter, useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

function ArtificialIntelligence() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 1000,
    className: "myCustomCarousel",
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          arrows: false,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          arrows: false,
          slidesToShow: 1,
        },
      },
    ],
  };
  const baseUrl = location.href.includes("trainingncr.info")
    ? `http://trainingncr.info/techx/assets/images`
    : `/assets/images`;

  return (
    <>
      <div className="container webhosting-all-section ">
        <div className="row  mt-3 mb-3">
          <div className="col-lg-6 d-lg-block d-none">
            <div className="p-2">
              <h2 className="AI-text-title-one">
                <span className="heading-text-AI-banner">
                  Revolutionizing IT Outsourcing
                </span>{" "}
                Unleashing Unmatched Engineering Excellence at TECHX
              </h2>
              {/* <h3 className="sub-heading">
                Transform Your Business Landscape with TECHX's Unique Approach
                to IT Outsourcing
              </h3> */}
            </div>
          </div>
          <div className="col-lg-6 d-lg-flex justify-content-center align-items-center d-none">
            <div className="p-lg-3">
              <img
                src="assets/images/pages/ITOutsourcing/standard-quality-control-concept-m.jpg"
                className=" out_img"
                alt=""
                style={{ width: '100%' }}
              />
            </div>
          </div>
          <div className="col-lg-6 d-lg-none d-flex justify-content-center align-items-center">
            <div className="p-lg-3">
              <img
                src="assets/images/pages/ITOutsourcing/standard-quality-control-concept-m.jpg"
                className=" out_img"
                alt=""
                style={{ width: '100%' }}
              />
            </div>
          </div>
          <div className="col-lg-6 d-lg-none d-block">
            <div className="p-2">
              <h2 className="AI-text-title-one">
                <span className="heading-text-AI-banner">
                  Revolutionizing IT Outsourcing
                </span>{" "}
                Unleashing Unmatched Engineering Excellence at TECHX
              </h2>
              {/* <h3 className="sub-heading">
                Transform Your Business Landscape with TECHX's Unique Approach
                to IT Outsourcing
              </h3> */}
            </div>
          </div>
          {/* <div className="col-lg-6">
            <div className="aboutus-text-section">
              <h2 className="AI-text-title-one">
                <span className="heading-text-AI-banner">
                  Revolutionizing IT Outsourcing
                </span>{" "}
                Unleashing Unmatched Engineering Excellence at TECHX
              </h2>
              <h3 className="sub-heading">
                Transform Your Business Landscape with TECHX's Unique Approach
                to IT Outsourcing
              </h3>
            </div>
          </div>
          <div className="col-lg-6 d-flex justify-content-center align-items-center">
            <div className="d-flex justify-content-center align-items-center" id="itout-img1" style={{ width: '100%' }}>
              <img
                src="assets/images/pages/ITOutsourcing/standard-quality-control-concept-m.jpg"
                className="banner-AI-img out_img"
                alt=""
                style={{ width: '100%' }}
              />
            </div>
          </div> */}
        </div>
      </div>

      <div className="AI-card-section">
        <h2 className="AI-text-title-one text-center mt-5 white-theme-text-AI">
          <span className="heading-text-AI-banner">TechX </span>
        </h2>
        <p className="AI-text-title-three text-center width-text-tile-three mt-2">
          At TECHX, we don't just meet your outsourcing needs; we engineer
          success. Our commitment to excellence sets us apart, and here's why:
        </p>
        <div className="container mt-3">
          <div className="row d-flex justify-content-center">
            <div className="col-lg-4 mb-2 col-md-6 d-flex">
              <div className="AI-card-box mt-3 h-100" id="ai-card-box">
                <div className="AI-circle-box">
                  <MdOutlineIntegrationInstructions className="icon-AI-card" />
                </div>
                <h3 className="AI-text-title-two mt-1 white-theme-color" style={{ color: '#2edaf1' }}>
                  Unmatched Engineering Expertise
                </h3>
                <p className="AI-text-title-three mt-1">
                  Embark on a journey with TECHX, where our team of seasoned engineers transforms challenges into opportunities.
                </p>
              </div>
            </div>

            <div className="col-lg-4 mb-2 col-md-6 d-flex">
              <div className="AI-card-box mt-3 h-100" id="ai-card-box">
                <div className="AI-circle-box">
                  <GrTechnology className="icon-AI-card" />
                </div>
                <h3 className="AI-text-title-two mt-1 white-theme-color" style={{ color: '#2edaf1' }}>
                  Tailored Solutions for Every Business
                </h3>
                <p className="AI-text-title-three mt-1">
                  TECHX understands that one size doesn't fit all. That's why our IT Outsourcing services are customized to your business.
                </p>
              </div>
            </div>

            <div className="col-lg-4 mb-2 col-md-6 d-flex">
              <div className="AI-card-box mt-3 h-100">
                <div className="AI-circle-box">
                  <GiRelationshipBounds className="icon-AI-card" />
                </div>
                <h3 className="AI-text-title-two mt-1 white-theme-color" style={{ color: '#2edaf1' }}>
                  Cutting-Edge Technologies
                </h3>
                <p className="AI-text-title-three mt-1">
                  In a digital landscape constantly evolving, TECHX keeps you ahead of the curve, harnessing cutting-edge technologies.
                </p>
              </div>
            </div>

            <div className="col-lg-4 mb-2 col-md-6 d-flex">
              <div className="AI-card-box mt-3 h-100">
                <div className="AI-circle-box">
                  <MdOutlineIntegrationInstructions className="icon-AI-card" />
                </div>
                <h3 className="AI-text-title-two mt-1 white-theme-color" style={{ color: '#2edaf1' }}>
                  Strategic IT Outsourcing
                </h3>
                <p className="AI-text-title-three mt-1">
                  TECHX doesn't just offer outsourcing; we provide strategic partnerships, driving efficiency and business success.
                </p>
              </div>
            </div>

            <div className="col-lg-4 mb-2 col-md-6 d-flex">
              <div className="AI-card-box mt-3 h-100">
                <div className="AI-circle-box">
                  <MdOutlineIntegrationInstructions className="icon-AI-card" />
                </div>
                <h3 className="AI-text-title-two mt-1 white-theme-color" style={{ color: '#2edaf1' }}>
                  Proactive Risk Management
                </h3>
                <p className="AI-text-title-three mt-1">
                  TECHX takes a proactive stance on risk management, implementing robust measures to secure your data and systems.
                </p>
              </div>
            </div>

            <div className="col-lg-4 mb-2 col-md-6 d-flex">
              <div className="AI-card-box mt-3 h-100">
                <div className="AI-circle-box">
                  <MdOutlineIntegrationInstructions className="icon-AI-card" />
                </div>
                <h3 className="AI-text-title-two mt-1 white-theme-color" style={{ color: '#2edaf1' }}>
                  Unrivaled Customer Support
                </h3>
                <p className="AI-text-title-three mt-1">
                  TECHX provides unwavering commitment to customer satisfaction with dedicated support available around the clock.
                </p>
              </div>
            </div>
{/* 
            <div className="col-lg-4 mb-2 col-md-6 d-flex">
              <div className="AI-card-box mt-3 h-100">
                <div className="AI-circle-box">
                  <MdOutlineIntegrationInstructions className="icon-AI-card" />
                </div>
                <h3 className="AI-text-title-two mt-1 white-theme-color" style={{ color: '#2edaf1' }}>
                  Continuous Innovation
                </h3>
                <p className="AI-text-title-three mt-1">
                  Innovation is the heartbeat of TECHX, with engineers driven to create solutions that redefine industry standards.
                </p>
              </div>
            </div> */}
          </div>
        </div>


        <div className="container mt-5">
          <div className="AI-slider-section mt-5">
            <h1 className="slider-heading textColor">
              Categories of IT Outsourcing Services at{" "}
              <span className="slider-logo">TECHX</span>
            </h1>
            <Slider {...settings}>
              <div>
                <div className="AI-slider-box Aislider1">
                  <ImQuotesLeft className="AI-slider-icon" />
                  <h3 className="AI-text-title-two white-theme-color">
                    Strategic IT Planning and Consulting:
                  </h3>
                  <div className="quotes-slider-AI">
                    <ImQuotesRight className="icon-slider-quotes" />
                  </div>
                </div>
              </div>
              <div>
                <div className="AI-slider-box Aislider2">
                  <ImQuotesLeft className="AI-slider-icon" />
                  <h3 className="AI-text-title-two white-theme-color">
                    Software Development and Engineering
                  </h3>
                  <div className="quotes-slider-AI">
                    <ImQuotesRight className="icon-slider-quotes" />
                  </div>
                </div>
              </div>
              <div>
                <div className="AI-slider-box Aislider3">
                  <ImQuotesLeft className="AI-slider-icon" />
                  <h3 className="AI-text-title-two white-theme-color">
                    Cybersecurity and Risk Management
                  </h3>
                  <div className="quotes-slider-AI ">
                    <ImQuotesRight className="icon-slider-quotes" />
                  </div>
                </div>
              </div>
              <div>
                <div className="AI-slider-box Aislider4">
                  <ImQuotesLeft className="AI-slider-icon" />
                  <h3 className="AI-text-title-two white-theme-color">
                    AI and Emerging Technologies Integration:
                  </h3>
                  <div className="quotes-slider-AI">
                    <ImQuotesRight className="icon-slider-quotes" />
                  </div>
                </div>
              </div>
              <div>
                <div className="AI-slider-box Aislider5">
                  <ImQuotesLeft className="AI-slider-icon" />
                  <h3 className="AI-text-title-two white-theme-color ">
                    24/7 Technical Support and Monitoring:
                  </h3>
                  <div className="quotes-slider-AI">
                    <ImQuotesRight className="icon-slider-quotes" />
                  </div>
                </div>
              </div>
              <div>
                <div className="AI-slider-box Aislider6">
                  <ImQuotesLeft className="AI-slider-icon" />
                  <h3 className="AI-text-title-two white-theme-color ">
                    Customized IT Solutions for Industry-specific Needs
                  </h3>
                  <div className="quotes-slider-AI">
                    <ImQuotesRight className="icon-slider-quotes" />
                  </div>
                </div>
              </div>
              <div>
                <div className="AI-slider-box Aislider6">
                  <ImQuotesLeft className="AI-slider-icon" />
                  <h3 className="AI-text-title-two white-theme-color ">
                    Efficient Data Management and Backup Solutions
                  </h3>
                  <div className="quotes-slider-AI">
                    <ImQuotesRight className="icon-slider-quotes" />
                  </div>
                </div>
              </div>
            </Slider>
          </div>
          ----------------------------------------------------------------------
          <div style={{ justifyContent: "center" }} id="itout-last">
            <p className="AI-text-title-three mt-1 text-center width-text-tile-three bottom-txt">
              Embrace a new era of IT Outsourcing with TECHX – where engineering
              brilliance meets unmatched dedication to your success. Let us
              engineer solutions that propel your business beyond limits
            </p>
          </div>
        </div>
      </div>
      <ThemeSetting />
    </>
  );
}

export default ArtificialIntelligence;
