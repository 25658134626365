import ThemeSetting from "./Include/ThemeSetting"
import { IoHardwareChip } from "react-icons/io5";
import { GrValidate } from "react-icons/gr";
import { FaHandsHelping } from "react-icons/fa";
import Aos from "aos";
import 'aos/dist/aos.css';
import { useEffect } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";




const HardwareInstallConfig = () => {
    useEffect(() => {
        Aos.init({ duration: 3000 })
    }, []);
    return (
        <>
            <div className="container mt-2 mb-2" >
                <div className="row">
                    <div className="col-lg-6 px-2 py-2" data-aos="fade-left">
                        <img src="assets/images/pages/HardwareInstallConfig/installhardware.png" className="w-100"  />
                    </div>
                    <div className="col-lg-6 d-flex flex-column justify-content-center align-items-center">
                        <h2 className="AI-text-title-one text-lg-left text-center mb-sm-2 mb-1" data-aos="fade-up">
                            <span className="heading-text-AI-banner ">Hardware </span> Install & Configuration Services
                        </h2>
                        <p className="f_18 textColor text-justify" data-aos="fade-down">At TechX, we offer comprehensive hardware installation and configuration services designed to ensure your technology infrastructure operates smoothly and efficiently. </p>
                    </div>
                </div>

            </div>
            <div className="container py-sm-2">
                <h2 className="AI-text-title-one text-center mt-md-5 mt-1 white-theme-text-AI mb-2" data-aos="fade-down">
                    On-Site <span className="heading-text-AI-banner ">Installation </span>
                </h2>
                <p className="f_18 textColor text-justify" data-aos="fade-up">Our on-site installation service guarantees a seamless setup of your hardware, directly at your business location. We manage everything from unboxing to full integration, ensuring your systems are up and running with minimal disruption.</p>

                <div className="row justify-content-center">
                    <div className="col-lg-4 col-md-6 mb-2  d-flex align-items-stretch" data-aos="fade-zoom-out">
                        <div className=" h_100 hardware_box mt-3 ">
                            <div className="AI-circle-box">
                                <IoHardwareChip className="icon-AI-card" />
                            </div>
                            <h3 className="AI-text-title-two mt-1 white-theme-color" style={{ color: "#2edaf1" }}>
                                Comprehensive Hardware Setup
                            </h3>
                            <p className="AI-text-title-three mt-1">
                                We install servers, workstations, networking equipment, and more, ensuring all components are properly connected and fully operational.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 mb-2  d-flex align-items-stretch" data-aos="fade-zoom-out">
                        <div className=" h_100 hardware_box mt-3">
                            <div className="AI-circle-box">
                                <GrValidate className="icon-AI-card" />
                            </div>
                            <h3 className="AI-text-title-two mt-1 white-theme-color" style={{ color: "#2edaf1" }}>
                                Testing & Validation
                            </h3>
                            <p className="AI-text-title-three mt-1">After installation, our technicians perform rigorous testing to validate that all hardware functions correctly and meets your business requirements. </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 mb-2  d-flex align-items-stretch" data-aos="fade-zoom-out">
                        <div className=" h_100 hardware_box mt-3">
                            <div className="AI-circle-box">
                                <FaHandsHelping className="icon-AI-card" />
                            </div>
                            <h3 className="AI-text-title-two mt-1 white-theme-color" style={{ color: "#2edaf1" }}>
                                Training & Handover
                            </h3>
                            <p className="AI-text-title-three mt-1">Once the installation is complete, we provide training to your team, ensuring they are familiar with the new hardware and can use it effectively.
                            </p>
                        </div>
                    </div>

                </div>
            </div>
            <div className="container py-sm-2 mt-sm-4 mt-2">
                <div className="row">
                    <div className="col-lg-6 d-flex  justify-content-center align-items-center" data-aos="fade-zoom-in">
                        <img src="assets/images/pages/HardwareInstallConfig/remote.png" className="w-100" data-aos="fade-zoom-in"/>
                    </div>
                    <div className="col-lg-6 d-flex flex-column justify-content-start py-lg-2 align-items-start">
                        <h2 className="AI-text-title-one text-lg-left text-center   white-theme-text-AI mb-2"  data-aos="fade-up">
                            Remote Setup Assistance
                        </h2>
                        <p className="f_18 textColor text-justify mb-1"  data-aos="fade-up">TechX offers remote setup assistance for businesses with distributed teams or those preferring a hands-off approach to installation. Our remote services ensure your hardware is installed correctly, regardless of your location.</p>
                        <p className="f_18 textColor text-justify mb-1"  data-aos="fade-up"><strong style={{ color: "#2edaf1" }}>Step-by-Step Guidance</strong> : Our technicians walk you through the installation process via phone or video call, making it easy for you to follow along and set up your hardware with confidence.</p>
                        <p className="f_18 textColor text-justify "  data-aos="fade-up"><strong style={{ color: "#2edaf1" }}>Remote Desktop Support</strong> : We can take control of your system remotely to configure hardware, install drivers, and ensure everything is working as intended.</p>
                    </div>
                </div>

            </div>
            <div className="container py-sm-2 mt-sm- mt-2">
                <h2 className="AI-text-title-one text-center  white-theme-text-AI mb-2" data-aos="fade-right">
                    <span className="heading-text-AI-banner">Configuration </span> Service
                </h2>
                <p className="f_18 textColor text-justify" data-aos="fade-left">Proper configuration is essential to getting the most out of your hardware. At TechX, we offer specialized configuration services that align your systems with your operational needs and security standards. </p>
                <div className="row nm-style4 mt-sm-2 mt-1">
                    <div className="col-lg-4 col-md-6 mb-sm-2 mb-1">
                        <h3 className="textColor">Network Configuration</h3>
                        <p data-aos="fade-rown">
                            <li>Customized network architecture</li>
                            <li>Performance optimization strategies</li>
                            <li>Router and switch configuration</li>
                            <li>Firewall setup and security enhancements</li>
                            <li>Wireless network setup and management</li>
                        </p>
                    </div>

                    <div className="col-lg-4 col-md-6 mb-sm-2 mb-1">
                        <h3 className="textColor">System Optimization</h3>
                        <p data-aos="fade-rown">
                            <li>Hardware performance tuning</li>
                            <li>System parameter adjustments</li>
                            <li>Installation of the latest updates and patches</li>
                            <li>Resource allocation optimization</li>
                            <li>Data storage and retrieval optimization</li>
                        </p>
                    </div>

                    <div className="col-lg-4 col-md-6 mb-sm-2 mb-1">
                        <h3 className="textColor">Integration with Existing Systems</h3>
                        <p data-aos="fade-rown">
                            <li>Seamless hardware and software integration</li>
                            <li>Compatibility testing and validation</li>
                            <li>Data migration and synchronization</li>
                            <li>Legacy system integration</li>
                            <li>Customized API connections</li>
                        </p>
                    </div>

                    <div className="col-lg-4 col-md-6 mb-sm-2 mb-1">
                        <h3 className="textColor">Security Enhancements</h3>
                        <p data-aos="fade-rown">
                            <li>Advanced encryption techniques</li>
                            <li>Multi-layered security protocols</li>
                            <li>Regular vulnerability assessments</li>
                            <li>Access control and management</li>
                            <li>Threat detection and response systems</li>
                        </p>
                    </div>

                    <div className="col-lg-4 col-md-6 mb-sm-2 mb-1">
                        <h3 className="textColor">Ongoing Support & Maintenance</h3>
                        <p data-aos="fade-rown">
                            <li>Regular system check-ups</li>
                            <li>24/7 remote monitoring</li>
                            <li>Proactive maintenance strategies</li>
                            <li>Hardware repair and replacement services</li>
                            <li>Long-term upgrade and expansion planning</li>
                        </p>
                    </div>
                    <div className="col-lg-4 col-md-6 mb-sm-2 mb-1">
                        <h3 className="textColor">Hardware Setup</h3>
                        <p data-aos="fade-rown">
                            <li>Install Server</li>
                            <li>Setup workstations</li>
                            <li>Setup Network equipment</li>
                            <li>Connections of equipment</li>
                            <li>Full operations</li>
                        </p>
                    </div>


                </div>
            </div>
            <div className="container py-sm-2 mt-sm- mt-2 mb-2">
                <h2 className="AI-text-title-one  text-center   white-theme-text-AI mb-2" data-aos="fade-up">
                    Custom Hardware Solutions
                </h2>
                <div className="row mt-2">
                    <div className="col-lg-6 d-flex  justify-content-center align-items-center" data-aos="fade-zoom-in">
                        <img src="assets/images/pages/HardwareInstallConfig/customrhardware.png" className="w-100" data-aos="fade-zoom-in"/>
                    </div>
                    <div className="col-lg-6 d-flex flex-column  justify-content-center align-items-center" data-aos="fade-up">
                        <p className="f_18 textColor text-justify mb-1">In addition to our standard services, TechX provides custom hardware solutions to address unique business challenges. Whether you need specialized equipment or a fully tailored IT setup, our team will design and implement a solution that fits your exact needs.</p>
                        <p className="f_18 textColor text-justify mb-1"><strong style={{ color: "#2edaf1" }}>Consultation & Needs Assessment</strong> : We start with a thorough understanding of your business goals and technology requirements.</p>
                        <p className="f_18 textColor text-justify mb-1"><strong style={{ color: "#2edaf1" }}>Design & Prototyping</strong> : Our experts design a custom solution, complete with detailed prototypes for your review and feedback.</p>
                        <p className="f_18 textColor text-justify mb-1"><strong style={{ color: "#2edaf1" }}>Implementation & Support</strong> : Once the design is finalized, we handle the installation, configuration, and ongoing support to ensure long-term success.
                        </p>
                    </div>
                </div>

            </div>


            
            <div className="container mt-sm-3">
                <div className="se-head mt-sm-3 mt-2 mb-5">
                    <h3 className="se-title-1">FAQS</h3>
                    <h4 className="se-title-2">Got questions?<br />Well, we've got answers.</h4>
                    <Link to="/support"
                        className="AI-text-title-one-btn" style={{ margin: 'auto' }}
                    >
                        Support
                    </Link>
                </div>

            </div>
            <ThemeSetting />
        </>
    )


}

export default HardwareInstallConfig